import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import { filters as filtersKeys } from 'constants/assessmentsFilters';

const { Types, Creators } = createActions(
  {
    fetchAssessments: [],
    fetchAssessmentsSuccess: ['assessmentsList'],
    setNavigation: ['navigation'],
    setAssessmentsErrors: ['error'],
    setAssessmentsLoading: ['isloadingAssessment'],
    fetchFilters: [],
    fetchFiltersSuccess: ['filters'],
    setIsFiltersReady: ['isFiltersReady'],
    setFiltersLoading: ['isloadingFilters'],
    setSelectedFilters: ['filters'],
    applySelectedFilters: ['filters'],
    setOrdering: ['ordering'],
    setSearch: ['search'],
    resetFilters: [],
    resetAssessments: [],
  },
  { prefix: '@BE-ASSESSMENTS/' }
);

export const AssessmentsActions = Creators;
export const AssessmentsTypes = Types;

export const INITIAL_STATE = Immutable({
  assessmentsList: null,
  isloadingAssessment: false,
  isloadingFilters: {
    [filtersKeys.providers]: false,
    [filtersKeys.organizations]: false,
    [filtersKeys.types]: false,
    [filtersKeys.status]: false,
  },
  isFiltersReady: false,
  filters: {
    [filtersKeys.providers]: [],
    [filtersKeys.organizations]: [],
    [filtersKeys.types]: [
      {
        value: 'intake',
        label: 'Intake',
        key: 'types-intake',
      },
      {
        value: 'risk_stratification',
        label: 'Protocol Intake',
        key: 'types-risk_stratification',
      },
      {
        value: 'reassessment',
        label: 'Reassessment',
        key: 'types-reassessment',
      },
    ],
    [filtersKeys.status]: [
      {
        value: 'Pending',
        label: 'Pending',
        key: 'statuses-pending',
      },
      {
        value: 'Completed',
        label: 'Completed',
        key: 'statuses-completed',
      },
      {
        value: 'Missed',
        label: 'Missed',
        key: 'statuses-missed',
      },
    ],
  },
  selectedFilters: {
    [filtersKeys.providers]: [],
    [filtersKeys.organizations]: [],
    [filtersKeys.types]: [],
    [filtersKeys.status]: [
      {
        value: 'Pending',
        label: 'Pending',
        key: 'statuses-pending',
      },
    ],
    [filtersKeys.ieDateRange]: null,
    [filtersKeys.scheduldateDateRange]: {
      start: moment(new Date()).subtract(6, 'days').startOf('day').toDate(),
      end: new Date(),
    },
  },
  navigation: {
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  },
  search: null,
  ordering: null,
  errors: [],
});

export const AssessmentsSelectors = {
  getAssessments: ({ assessments }) => assessments.assessmentsList,
  getAssessmentsLoading: ({ assessments }) => assessments.isloadingAssessment,
  getIsFiltersReady: ({ assessments }) => assessments.isFiltersReady,
  getFiltersIsLoading: ({ assessments }) => assessments.isloadingFilters,
  getFilters: ({ assessments }) => assessments.filters,
  getSelectedFilters: ({ assessments }) => assessments.selectedFilters,
  getNavigation: ({ assessments }) => assessments.navigation,
  getSearch: ({ assessments }) => assessments.search ?? '',
  getOrdering: ({ assessments }) => assessments.ordering,
  getErrors: ({ assessments }) => assessments.errors,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchAssessmentsSuccess = (state, { assessmentsList }) =>
  state.merge({ assessmentsList });

const setAssessmentsLoading = (state, { isloadingAssessment }) =>
  state.merge({ isloadingAssessment });

const setNavigation = (state, { navigation }) =>
  state.merge({
    navigation: {
      ...state.navigation,
      ...navigation,
    },
  });

const setSearch = (state, { search }) =>
  state.merge({
    search,
    navigation: {
      ...state.navigation,
      currentPage: 1,
    },
  });

const setAssessmentsErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
    assessmentsList: [],
    navigation: INITIAL_STATE.navigation,
  });

const fetchFiltersSuccess = (state, { filters }) =>
  state.merge({
    filters: { ...state.filters, ...filters },
  });

const setFiltersLoading = (state, { isloadingFilters }) =>
  state.merge({
    isloadingFilters: { ...state.isloadingFilters, ...isloadingFilters },
  });

const setIsFiltersReady = (state, { isFiltersReady }) =>
  state.merge({
    isFiltersReady,
  });

const setSelectedFilters = (state, { filters }) =>
  state.merge({
    selectedFilters: { ...state.selectedFilters, ...filters },
  });

const setOrdering = (state, { ordering }) => state.merge({ ordering });

const resetAssessments = (state) => state.merge({ assessmentsList: null });

export const AssessmentsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_ASSESSMENTS_SUCCESS]: fetchAssessmentsSuccess,
  [Types.SET_ASSESSMENTS_LOADING]: setAssessmentsLoading,
  [Types.SET_ASSESSMENTS_ERRORS]: setAssessmentsErrors,
  [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
  [Types.SET_FILTERS_LOADING]: setFiltersLoading,
  [Types.SET_IS_FILTERS_READY]: setIsFiltersReady,
  [Types.RESET_ASSESSMENTS]: resetAssessments,
  [Types.SET_SELECTED_FILTERS]: setSelectedFilters,
  [Types.SET_NAVIGATION]: setNavigation,
  [Types.SET_SEARCH]: setSearch,
  [Types.SET_ORDERING]: setOrdering,
});
